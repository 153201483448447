const newSessionID = async (
  webSocketUrl,
  requestOptions,
  isOfficialApi = false
) => {
  let url = `${webSocketUrl}${isOfficialApi ? 'session/official' : 'session'}`;

  const data = await fetch(url, requestOptions)
    .then(response => response.json())
    // eslint-disable-next-line no-console
    .catch(err => console.error(err));

  return data;
};

const updateSessionId = async (url, requestOptions) => {
  const path = `${url}api/v1/sessions`;
  // eslint-disable-next-line no-console
  fetch(path, requestOptions).catch(err => console.error(err));
};

const restartSessionService = async (url, requestOptions) => {
  const path = `${url}restart`;
  // eslint-disable-next-line no-console
  fetch(path, requestOptions).catch(err => console.error(err));
};

const reconnectSessionOnService = async (url, requestOptions) => {
  const path = `${url}api/v1/sessions/reconnect`;
  // eslint-disable-next-line no-console
  const data =  await fetch(path, requestOptions).then(response => response.json()).catch(err => console.error(err));
  return data;
};

export { newSessionID, reconnectSessionOnService, restartSessionService, updateSessionId };

