<template>
  <ul
    v-if="items.length"
    class="vertical dropdown menu mention--box"
  >
    <li
      v-for="(item, index) in arrangeByResponseType(items)"
      :id="`mention-item-${index}`"
      :key="item.key"
      :class="{ active: index === selectedIndex }"
      @click="onListItemSelection(index)"
      @mouseover="onHover(index)"
    >
      <a class="text-truncate" v-if="item.response_type === 'waba'">
        <strong>{{ item.label }}</strong> - {{ item.description }} - {{ $t('MENTION_BOX.CANNED_RESPONSES_WABA') }}
      </a>
      <a class="text-truncate canned-responses" v-else>
        <strong>{{ item.label }}</strong><span> - {{ item.description }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => {},
    },
    lastMessage: {
      type: Object,
      default: () => ({}),
    },
    isWhatsapp: {
      type: Boolean,
      default: false,
    },
    newConversation: {
      type: Boolean,
      default: false,
    },
    isAgents: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      cannedResponsesWaba: '',
    };
  },
  watch: {
    items(newItems) {
      if (this.fetchRoute() && this.cannedResponsesWaba === '') {
        this.fetchCannedResponsesWaba(newItems)
      }
      if (newItems.length < this.selectedIndex + 1) {
        this.selectedIndex = 0;
      }
    },
  },
  mounted() {
    document.addEventListener('keydown', this.keyListener);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyListener);
  },
  methods: {
    filterItems(items) {
      if(!this.isAgents){
        if (this.isWhatsapp) {
        if(this.hasPassed24Hours() || this.newConversation){
          return items.filter(item => item.response_type !== 'default')
        }
        return items
      } else {
        return items.filter(item => item.response_type === 'default');
      }
      }
      return items
    },
    hasPassed24Hours() {
      if (this.lastMessage?.created_at && this.isWhatsapp) {
        const secondsIn24Hours = 86400;
        const currentTime = Math.floor(Date.now() / 1000);
        return (currentTime - this.lastMessage.created_at) >= secondsIn24Hours;
      }
      return !!this.isWhatsapp;
    },
    arrangeByResponseType(array) {
      array.sort((a, b) => {
        if (a.response_type === 'waba' && b.response_type !== 'waba') {
          return -1;
        } else if (a.response_type !== 'waba' && b.response_type === 'waba') {
          return 1;
        }
        return 0;
      });

      return this.filterItems(array);
    },
    filterCannedResponsesWabaOrItems() {
      if (this.fetchRoute()) {
        return this.cannedResponsesWaba
      } else {
        return this.items
      }
    },
    fetchRoute() {
      return this.$route.name === 'contact_profile_dashboard'
    },
    fetchCannedResponsesWaba(items) {
      this.cannedResponsesWaba = items.reduce((accumulator, item) => {
        if (item.response_type === 'waba') {
          accumulator.push(item);
        }
        return accumulator;
      }, []);
    },
    getTopPadding() {
      const filterItems = this.filterItems(this.items)
      if (this.fetchRoute()) {
        if (this.cannedResponsesWaba.length <= 4) {
          return -(this.cannedResponsesWaba.length * 2);
        }
      } else if (filterItems.length <= 4) {
        return -(filterItems.length * 2.8 + 2.5);
      }
      return -14;
    },
    isUp(e) {
      return e.keyCode === 38 || (e.ctrlKey && e.keyCode === 80); // UP, Ctrl-P
    },
    isDown(e) {
      return e.keyCode === 40 || (e.ctrlKey && e.keyCode === 78); // DOWN, Ctrl-N
    },
    isEnter(e) {
      return e.keyCode === 13;
    },
    keyListener(e) {
      if (this.isUp(e)) {
        if (!this.selectedIndex) {
          this.selectedIndex = this.items.length - 1;
        } else {
          this.selectedIndex -= 1;
        }
      }
      if (this.isDown(e)) {
        if (this.selectedIndex === this.items.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex += 1;
        }
      }
      if (this.isEnter(e)) {
        this.onMentionSelect();
      }
      this.$el.scrollTop = 28 * this.selectedIndex;
    },
    onHover(index) {
      this.selectedIndex = index;
    },
    onListItemSelection(index) {
      this.selectedIndex = index;
      this.onMentionSelect();
    },
    onMentionSelect() {
      this.$emit('mention-select', this.filterItems(this.items)?.[this.selectedIndex]);
    },
  },
};
</script>

<style scoped lang="scss">
.mention--box {
  background: var(--white);
  border-bottom: var(--space-small) solid var(--white);
  border-top: 1px solid var(--color-border);
  left: 0;
  max-height: 14rem;
  overflow: auto;
  padding-top: var(--space-small);
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 60%;

  .active a {
    background: var(--w-500);
  }
}

.canned-responses {
  color: var(--color-body);
}
</style>
