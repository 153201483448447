<template>
  <div>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_AGENTS_SUB_TEXT')"
    >
      <multiselect
        v-model="selectedAgents"
        :options="agentList"
        track-by="id"
        label="name"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        placeholder="Pick some"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
        @select="$v.selectedAgents.$touch"
      />

      <woot-submit-button
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        :loading="isAgentListUpdating"
        @click="updateAgents"
      />
    </settings-section>

    <!-- Agent Auto Assigned -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNMENT_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableAutoAssignment"
            v-model="enableAutoAssignment"
            type="checkbox"
            @change="handleEnableAutoAssignment"
          />
          <label for="enableAutoAssignment">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.AUTO_ASSIGNMENT_SUB_TEXT') }}
        </p>
      </label>

      <!-- disabling this block temporarily -->
      <div
        v-if="enableAutoAssignment && isEnterprise && false"
        class="max-assignment-container"
      >
        <woot-input
          v-model.trim="maxAssignmentLimit"
          type="number"
          :class="{ error: $v.maxAssignmentLimit.$error }"
          :error="maxAssignmentLimitErrors"
          :label="$t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT')"
          @blur="$v.maxAssignmentLimit.$touch"
        />

        <p class="help-text">
          {{ $t('INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_SUB_TEXT') }}
        </p>

        <woot-submit-button
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :disabled="$v.maxAssignmentLimit.$invalid"
          @click="updateInbox"
        />
      </div>
    </settings-section>

    <!-- Agent Assigned Conversations -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNED_CONVERSATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_ASSIGNED_CONVERSATION_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableViewAssignedConversations"
            v-model="enableViewAssignedConversations"
            type="checkbox"
            @change="handleViewAssignedConversations"
          />
          <label for="enableViewAssignedConversations">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_ASSIGNED_CONVERSATION') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_ASSIGNED_CONVERSATION_SUB_TEXT') }}
        </p>
      </label>
    </settings-section>

    <!-- Agent Fowarding Conversations -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.VIEW_FOWARDING_CONVERSATION')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.AGENT_FOWARDING_CONVERSATION_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableViewAssignedConversations"
            v-model="enableFowardingConversations"
            type="checkbox"
            @change="handleEnableFowardingConversations"
          />
          <label for="enableFowardingConversations">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.AGENT_FOWARDING_CONVERSATION') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_FOWARDING_CONVERSATION_SUB_TEXT') }}
        </p>
      </label>
    </settings-section>

    <!-- Routing Outside Team -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.ROUTING_OUTSIDE_TEAM')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.ROUTING_OUTSIDE_TEAM_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableRoutingOutsideTeam"
            v-model="enableRoutingOutsideTeam"
            type="checkbox"
            @change="handleEnableRoutingOutsideTeam"
          />
          <label for="enableRoutingOutsideTeam">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_ROUTING_OUTSIDE_TEAM') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_ROUTING_OUTSIDE_TEAM_SUB_TEXT') }}
        </p>
      </label>
    </settings-section>

    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.VIEW_PRIVATE_MESSAGES')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.PRIVATE_MESSAGES_SUB_TEXT')"
    >
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enablePrivacyMessages"
            v-model="enablePrivacyMessages"
            type="checkbox"
            @change="handleEnablePrivacyMessages"
          />
          <label for="enablePrivacyMessages">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_PRIVATE_MESSAGES') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.VIEW_PRIVATE_MESSAGES_SUB_TEXT') }}
        </p>
      </label>
    </settings-section>

    <!-- Transfer By Inactivity -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.TRANSFER_BY_INACTIVITY')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.TRANSFER_BY_INACTIVITY_SUB_TEXT')"
    >
      <!-- CheckBox -->
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableTransferByInactivity"
            v-model="enableTransferByInactivity"
            type="checkbox"
          />
          <label for="enableTransferByInactivity">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_TRANSFER_BY_INACTIVITY') }}
          </label>
          <p class="help-text">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_TRANSFER_BY_INACTIVITY_SUB_TEXT') }}
          </p>
        </div>
      </label>
      <!-- Select Team To Transfer -->
      <label class="medium-9 columns settings-item" v-if="enableTransferByInactivity">
        <div class="select">
          <div class="task-wrap">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.TRANSFER_SELECT_TEAM') }}
            <select class="task__type" v-model="selectedTransferTeam">
              <option class="placeholder" value="" disabled selected hidden>
                {{ $t('INBOX_MGMT.SETTINGS_POPUP.TRANSFER_SELECT_TEAM') }}
              </option>
              <option
                v-for="team in teams"
                :key="team.id"
                :value="team.id"
              >
                {{ team.name }} - {{ team.description }}
              </option>
            </select>
          </div>
        </div>
      </label>
      <!-- Select Time to Transfer if selected a team -->
      <label class="medium-9 columns settings-item" v-if="enableTransferByInactivity">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.TIME_TO_EXECUTE') }}
        <div class="row select">
          <multiselect
            v-model="timeTransferIdleConversation"
            :options="timeToTransfer"
            track-by="seconds"
            label="time"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="false"
            selected-label
            :select-label="$t('INBOX_MGMT.SETTINGS_POPUP.TIME_TO_EXECUTE')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="$v.timeTransferIdleConversation.$touch"
          />
        </div>
      </label>
      <woot-submit-button
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        @click="handleEnableTransferByInactivity"
      />
    </settings-section>

    <!-- Closure By Inactivity -->
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.CLOSURE_BY_INACTIVITY')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.CLOSURE_BY_INACTIVITY_SUB_TEXT')"
    >
      <!-- CheckBox -->
      <label class="medium-9 columns settings-item">
        <div class="enter-to-send--checkbox">
          <input
            id="enableClosureByInactivity"
            v-model="enableClosureByInactivity"
            type="checkbox"
          />
          <label for="enableClosureByInactivity">
            {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CLOSURE_BY_INACTIVITY') }}
          </label>
        </div>

        <p class="help-text">
          {{ $t('INBOX_MGMT.SETTINGS_POPUP.ENABLE_CLOSURE_BY_INACTIVITY_SUB_TEXT') }}
        </p>
      </label>
      <!-- Select Statuses to Closure -->
      <label class="medium-9 columns settings-item" v-if="enableClosureByInactivity">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.TIME_TO_EXECUTE') }}
        <div class="row select">
          <multiselect
            v-model="closureStatuses"
            :options="statusOptions"
            track-by="id"
            label="status"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="false"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="$v.closureStatuses.$touch"
          />
        </div>
      </label>
      <!-- Select Time to Closure -->
      <label class="medium-9 columns settings-item" v-if="enableClosureByInactivity">
        {{ $t('INBOX_MGMT.SETTINGS_POPUP.TIME_TO_EXECUTE') }}
        <div class="row select">
          <multiselect
            v-model="timeClosureIdleConversation"
            :options="timeToTransfer"
            track-by="seconds"
            label="time"
            :multiple="false"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="false"
            selected-label
            :select-label="$t('INBOX_MGMT.SETTINGS_POPUP.TIME_TO_EXECUTE')"
            :deselect-label="$t('FORMS.MULTISELECT.ENTER_TO_REMOVE')"
            @select="$v.timeClosureIdleConversation.$touch"
          />
        </div>
      </label>
      <woot-submit-button
        :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
        @click="handleEnableClosureByInactivity"
      />
    </settings-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { minValue } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import SettingsSection from '../../../../../components/SettingsSection';

export default {
  components: {
    SettingsSection,
  },

  mixins: [
    alertMixin,
    configMixin
  ],
  
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedAgents: [],
      isAgentListUpdating: false,
      enableAutoAssignment: false,
      maxAssignmentLimit: null,
      enableViewAssignedConversations: false,
      enableFowardingConversations: false,
      enableRoutingOutsideTeam: false,
      enablePrivacyMessages: false,
      enableTransferByInactivity: false,
      transferLastUpdate: false,
      selectedTransferTeam: "",
      timeTransferIdleConversation: null,
      enableClosureByInactivity: false,
      closureLastUpdate: false,
      closureStatuses: "",
      timeClosureIdleConversation: null,
      timeToTransfer: [
        { seconds: 30, time: '30m' },
        { seconds: 60, time: '1h' },
        { seconds: 180, time: '3h' },
        { seconds: 360, time: '6h' },
        { seconds: 720, time: '12h' },
        { seconds: 1440, time: '24h' }
      ],
      statusOptions: [
        { id: 0, status: 'open' },
        { id: 1, status: 'resolved' },
        { id: 2, status: 'pending' },
        { id: 3, status: 'snoozed' }
      ],
    };
  },
  computed: {
    ...mapGetters({
      agentList: 'agents/getAgents',
      teams: 'teams/getTeams',
    }),
    maxAssignmentLimitErrors() {
      if (this.$v.maxAssignmentLimit.$error) {
        return this.$t(
          'INBOX_MGMT.AUTO_ASSIGNMENT.MAX_ASSIGNMENT_LIMIT_RANGE_ERROR'
        );
      }
      return '';
    },
  },
  watch: {
    inbox() {
      this.setDefaults();
    },
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      this.enableAutoAssignment = this.inbox.enable_auto_assignment;
      this.enableViewAssignedConversations = this.inbox.enable_view_assigned_conversations;
      this.enableFowardingConversations = this.inbox.enable_fowarding_conversation;
      this.enableRoutingOutsideTeam = this.inbox.enable_routing_outside_team;
      this.enablePrivacyMessages = this.inbox.enable_privacy_messages;
      this.enableTransferByInactivity = this.inbox.transfer_by_inactivity;
      this.transferLastUpdate = false;
      this.closureLastUpdate = false;
      this.selectedTransferTeam = this.inbox.transfer_teams || "";
      this.timeTransferIdleConversation = this.timeToTransfer.find(
        time => time.seconds === this.inbox.time_transfer_idle_conversation
      );
      this.enableClosureByInactivity = this.inbox.closure_by_inactivity;
      this.closureStatuses = this.inbox?.closure_statuses?.map(value => {
        const option = this.statusOptions.find(opt => opt.status === value);
        return { id: option.id, status: option.status };
      }) || "";
      this.timeClosureIdleConversation = this.timeToTransfer.find(
        time => time.seconds === this.inbox.time_closure_idle_conversation
      );
      this.maxAssignmentLimit = this.inbox.auto_assignment_config?.max_assignment_limit || null;
      this.fetchAttachedAgents();
    },
    async fetchAttachedAgents() {
      try {
        const response = await this.$store.dispatch('inboxMembers/get', {
          inboxId: this.inbox.id,
        });
        const {
          data: { payload: inboxMembers },
        } = response;
        this.selectedAgents = inboxMembers;
      } catch (error) {
        //  Handle error
      }
    },
    handleEnableAutoAssignment() {
      this.updateInbox();
    },
    handleViewAssignedConversations() {
      this.updateInbox();
    },
    handleEnableFowardingConversations() {
      this.updateInbox();
    },
    handleEnableRoutingOutsideTeam() {
      this.updateInbox();
    },
    handleEnablePrivacyMessages() {
      this.updateInbox();
    },
    handleEnableTransferByInactivity() {
      if (this.enableTransferByInactivity && this.selectedTransferTeam) {
        this.transferLastUpdate = true
        this.updateInbox();
      } else {
        this.transferLastUpdate = true
        this.selectedTransferTeam = null;
        this.timeTransferIdleConversation = null;
        if (!this.enableTransferByInactivity) {
          this.updateInbox();
        }
      }
    },
    handleEnableClosureByInactivity() {
      if (this.enableClosureByInactivity && this.closureStatuses.length > 0) {
        this.closureLastUpdate = true
        this.updateInbox();
      } else if (this.enableClosureByInactivity) {
        this.showAlert('Por favor, selecione pelo menos uma opção de status.');
      } else {
        this.closureLastUpdate = true
        this.closureStatuses = null;
        this.timeClosureIdleConversation = null;
        if (!this.enableClosureByInactivity) {
          this.updateInbox();
        }
      }
    },
    returnSeconds(obj) {
      if (obj?.seconds) {
        return obj?.seconds;
      } else {
        return null;
      }
    },
    toggleClosureOption(option) {
      if (this.closureStatuses.includes(option)) {
        const index = this.closureStatuses.indexOf(option);
        this.closureStatuses.splice(index, 1);
      } else {
        this.closureStatuses.push(option);
      }
    },
    async updateAgents() {
      const agentList = this.selectedAgents.map(el => el.id);
      this.isAgentListUpdating = true;
      try {
        await this.$store.dispatch('inboxMembers/create', {
          inboxId: this.inbox.id,
          agentList,
        });
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('AGENT_MGMT.EDIT.API.ERROR_MESSAGE'));
      }
      this.isAgentListUpdating = false;
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.inbox.id,
          formData: false,
          enable_auto_assignment: this.enableAutoAssignment,
          enable_view_assigned_conversations: this.enableViewAssignedConversations,
          enable_fowarding_conversation: this.enableFowardingConversations,
          enable_routing_outside_team: this.enableRoutingOutsideTeam,
          enable_privacy_messages: this.enablePrivacyMessages,
          transfer_by_inactivity: this.enableTransferByInactivity,
          verify_transfer_last_update: this.transferLastUpdate,
          transfer_teams: this.selectedTransferTeam,
          time_transfer_idle_conversation: this.returnSeconds(this.timeTransferIdleConversation),
          closure_by_inactivity: this.enableClosureByInactivity,
          verify_closure_last_update: this.closureLastUpdate,
          closure_statuses: this.closureStatuses,
          time_closure_idle_conversation: this.returnSeconds(this.timeClosureIdleConversation),
          auto_assignment_config: {
            max_assignment_limit: this.maxAssignmentLimit,
          },
        };
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.ERROR_MESSAGE'));
        throw new Error(error);
      }
    },
  },
  validations: {
    selectedAgents: {
      isEmpty() {
        return !!this.selectedAgents.length;
      },
    },
    closureStatuses: {
      isEmpty() {
        return !!this.closureStatuses.length;
      },
    },
    timeClosureIdleConversation: {
      isEmpty() {
        return !!this.timeClosureIdleConversation.length;
      },
    },
    timeTransferIdleConversation: {
      isEmpty() {
        return !!this.timeClosureIdleConversation.length;
      },
    },
    maxAssignmentLimit: {
      minValue: minValue(1),
    },
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
@import '~dashboard/assets/scss/mixins';

.max-assignment-container {
  padding-top: var(--space-slab);
  padding-bottom: var(--space-slab);
}

select option.placeholder {
  color: $color-gray !important;
  font-weight: $font-weight-normal !important;
  padding-top: var(--space-slab) !important;
}
</style>
