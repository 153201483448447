<template>
  <div class="date-picker">
    <date-picker
      v-if="dateType === 'date'"
      type="datetime"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="dateValue"
      :disabled-date="disableBeforeToday"
      @change="handleChange"
    />
    <date-picker
      v-if="dateType === 'timestamp'"
      type="datetime"
      :valueType="dateType"
      :confirm="true"
      :clearable="false"
      :editable="false"
      :confirm-text="confirmText"
      :placeholder="placeholder"
      :value="timestampValue"
      :disabled-date="disableBeforeToday"
      @change="handleChange"
    />
  </div>
</template>

<script>
import addDays from 'date-fns/addDays';
import DatePicker from 'vue2-datepicker';
export default {
  components: { DatePicker },
  props: {
    dateType: {
      type: String,
      default: 'date',
    },
    confirmText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    dateValue: {
      type: Date,
      default: null,
    },
    timestampValue: {
      type: Number,
      default: null,
    },
  },

  methods: {
    handleChange(value) {
      if (this.dateType === 'timestamp') {
        const timestampValue = value
        this.$emit('change', timestampValue);
      } else {
        const dateValue = value
        this.$emit('change', dateValue);
      }
    },
    disableBeforeToday(date) {
      const yesterdayDate = addDays(new Date(), -1);
      return date < yesterdayDate;
    },
  },
};
</script>
