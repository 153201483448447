<template>
    <div class="wizard-body small-9 columns">
        <page-header
                :header-title="$t('INBOX_MGMT.ADD.WHATSAPP.TITLE')"
                :header-content="$t('INBOX_MGMT.ADD.WHATSAPP.DESC')"
        />
        <div class="medium-8 columns">
            <label>
                {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.LABEL') }}
                <select v-model="provider">
                    <option value="whatsapp_cloud">
                        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.WHATSAPP_CLOUD') }}
                    </option>
                    <option value="twilio">
                        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PROVIDERS.TWILIO') }}
                    </option>
                </select>
            </label>
        </div>

        <twilio v-if="provider === 'twilio'" type="whatsapp" />
        <three-sixty-dialog-whatsapp v-else-if="provider === '360dialog'" />
        <cloud-whatsapp v-else />
    </div>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader';
import Twilio from './Twilio';
import ThreeSixtyDialogWhatsapp from './360DialogWhatsapp';
import CloudWhatsapp from './CloudWhatsapp';
export default {
    components: {
        PageHeader,
        Twilio,
        ThreeSixtyDialogWhatsapp,
        CloudWhatsapp,
    },
    data() {
        return {
            provider: 'whatsapp_cloud',
        };
    },
};
</script>
