import MessageFormatter from '../helpers/MessageFormatter';

export default {
  methods: {
    formatMessage(message, isATweet, isAPrivateNote) {
      const messageFormatter = new MessageFormatter(
        message,
        isATweet,
        isAPrivateNote
      );
      return messageFormatter.formattedMessage;
    },
    getPlainText(message, isATweet) {
      const messageFormatter = new MessageFormatter(message, isATweet);
      return messageFormatter.plainText;
    },
    truncateMessage(description = '') {
      if (description.length < 100) {
        return description;
      }

      return `${description.slice(0, 97)}...`;
    },
    findVariablesInMessage(message) {
      const variables = message.match(/{{(.*?)}}/g);

      if (variables) {
        return variables.map( variable => variable.replace(/{{|}}/g,'') );
      }

      return variables;
    },
    greetingFormat() {
      var hourNow = new Date().getHours();

      if (hourNow >= 0 && hourNow < 12) {
        return this.$t('CONVERSATION.GREETING_MESSAGE.MORNING');
      } else if (hourNow >= 12 && hourNow < 18) {
        return this.$t('CONVERSATION.GREETING_MESSAGE.AFTERNOON');
      } else {
        return this.$t('CONVERSATION.GREETING_MESSAGE.EVENING');
      }
    },
    formatCannedMessage(message, variables, variablesInMessage) {
      this.cannedMessageVariables = [];

      variablesInMessage.forEach((variable) => {
        this.cannedMessageVariables.push(
          variables[variable]
        );
        message = message.replace(`{{${variable}}}`, `${variables[variable]}`)
      });

      return message
    },
  },
};
