<template>
  <div>
    <div class="multiselect-wrap--small">
      <contact-details-item
        compact
        :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_WALLET')"
      >
        <template v-slot:button>
          <woot-button
            v-if="isAdmin && showSelfAssign"
            icon="arrow-right"
            variant="link"
            size="small"
            @click="onSelfAssign"
          >
            {{ $t('CONVERSATION_SIDEBAR.SELF_ASSIGN') }}
          </woot-button>
        </template>
      </contact-details-item>
      <multiselect-dropdown
        :options="agentsWallets"
        :selected-item="agentSelectedWallet"
        :multiselector-title="$t('CONVERSATION_SIDEBAR.ACCORDION.WALLETS')"
        :multiselector-placeholder="$t('AGENT_MGMT.MULTI_SELECTOR.PLACEHOLDER')"
        :no-search-result="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.NO_RESULTS.AGENT')
        "
        :input-placeholder="
          $t('AGENT_MGMT.MULTI_SELECTOR.SEARCH.PLACEHOLDER.AGENT')
        "
        :disabled="disabled()"
        @click="onClickAssignAgent"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ContactDetailsItem from './ContactDetailsItem.vue';
import MultiselectDropdown from 'shared/components/ui/MultiselectDropdown.vue';
import isAdmin from '../../../mixins/isAdmin';
import alertMixin from '../../../../shared/mixins/alertMixin';

export default {
  components: {
    ContactDetailsItem,
    MultiselectDropdown,
  },
  mixins: [isAdmin, alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      noneUser : {
        id: null,
        name: 'None',
        role: 'agent',
        confirmed: true,
        account_id: null,
        email: 'None',
      },
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      agentList: 'agents/getAgents',
    }),
    agentSelectedWallet() {
      return this.agentList.find(agent => this.contact.user_id === agent.id);
    },

    agentsWallets() {
      let agents = this.agentList || [];
      if (this.agentSelectedWallet) {
        return [
          ...[this.noneUser],
          ...agents,
        ]
      }

      return agents
    },
    showSelfAssign() {
      return this.contact.user_id !== this.currentUser.id
    }
  },
  mounted() {
    if (this.agentList.length === 0)
      this.$store.dispatch('agents/get');
  },
  methods: {
    disabled() {
      return this.contact.user_id &&
        this.contact.user_id !== this.currentUser.id &&
        !this.isAdmin ? true : false;
    },
    getContactObject(contactItem) {
      const contactObject = {
        id: contactItem.id,
        name: contactItem.name,
        email: contactItem.email,
        phone_number: contactItem.phoneNumber,
        identifier: contactItem.phoneNumber?.replace('+', ''),
        user_id: contactItem.user_id,
        additional_attributes: {
          ...contactItem.additional_attributes,
          description: contactItem.description,
          company_name: contactItem.companyName,
          social_profiles: contactItem.socialProfileUserNames,
        },
      };
      if (contactItem.avatarFile) {
        contactObject.avatar = this.avatarFile;
        contactObject.isFormData = true;
      }
      return contactObject;
    },
    onClickAssignAgent(agent) {
      const contact = {
        ...this.contact,
        user_id: agent.id,
      };
      this.updateContact(contact)
    },
    onSelfAssign() {
      const contact = {
        ...this.contact,
        user_id: this.currentUser.id,
      };
      this.updateContact(contact)
    },
    updateContact(contactItem) {
      this.$store.dispatch('contacts/update', this.getContactObject(contactItem))
      .then(() => {
        this.showAlert(this.$t('EDIT_CONTACT.UPDATED'));
      });
    }
  },
}
</script>