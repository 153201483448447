import { render, staticRenderFns } from "./ContactManageView.vue?vue&type=template&id=388c3878&scoped=true&"
import script from "./ContactManageView.vue?vue&type=script&lang=js&"
export * from "./ContactManageView.vue?vue&type=script&lang=js&"
import style0 from "./ContactManageView.vue?vue&type=style&index=0&id=388c3878&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388c3878",
  null
  
)

export default component.exports