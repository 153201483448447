import Vue from 'vue';

export const set = (state, data) => {
  state.records = data;
};

export const create = (state, data) => {
  state.records.push(data);
};

export const setSingleRecord = (state, data) => {
  const recordIndex = state.records.findIndex(record => record.id === data.id);
  if (recordIndex > -1) {
    state.records[recordIndex] = data;
  } else {
    create(state, data);
  }
};

export const update = (state, data) => {
  state.records.forEach((element, index) => {
    if (element.id === data.id) {
      Vue.set(state.records, index, data);
    }
  });
};

/* when you don't want to overwrite the whole object */
export const updateAttributes = (state, data) => {
  state.records.forEach((element, index) => {
    if (element.id === data.id) {
      Vue.set(state.records, index, { ...state.records[index], ...data });
    }
  });
};

export const updatePresence = (state, data) => {
  const keysData = Object.keys(data);

  let stateAgents = state.records;

  if ( stateAgents.length === 0 )
    return

  if ( keysData.length === 1 ) {
    clearAgentsState(state, data)
    return
  } else {
    stateAgents = stateAgents.map(element => {
      element.availability_status = data[element.id]
      return element
    })
  }

  stateAgents.forEach((element, index) => {
    Vue.set(
      stateAgents[index],
      'availability_status',
      element.availability_status || 'offline'
    );
  });
};

export const updateUniqPresence = (state, data) => {
  const keysData = Object.keys(data);

  let stateAgents = state.records;

    if ( stateAgents.length === 0 )
      return

    const agentIndex = stateAgents.findIndex(
    element =>
      element.id == keysData[0]
  )

  let agent = stateAgents[agentIndex]

  stateAgents[agentIndex].availability_status = data[agent.id]

  stateAgents.forEach((element, index) => {
    Vue.set(
      stateAgents[index],
      'availability_status',
      element.availability_status || 'offline'
    );
  });
};

const clearAgentsState = (state, data) => {
  const keysData = Object.keys(data)[0];

  state.records = state.records.map(
    element => {
      element.availability_status = element.id != keysData ?
        'offline' :
        element.availability_status

      return element
    }
  )
  updateUniqPresence(state, data)
}

export const destroy = (state, id) => {
  state.records = state.records.filter(record => record.id !== id);
};
