<template>
  <mention-box
    :items="items"
    :last-message="lastMessageInChat()"
    :is-whatsapp="isWhatsapp()"
    :new-conversation="newConversation"
    @mention-select="handleMentionClick"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';
import conversationMixin from '../../../mixins/conversations';

export default {
  components: { MentionBox },
  mixins: [ conversationMixin ],
  props: {
    searchKey: {
      type: String,
      default: '',
    },
    selectedInbox: {
      type: Object,
      default: () => ({}),
    },
    newConversation: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      cannedMessages: 'getCannedResponses',
    }),
    items() {
      return this.cannedMessages.map(cannedMessage => ({
        label: cannedMessage.short_code,
        key: cannedMessage.short_code,
        description: cannedMessage.content,
        response_type: cannedMessage.response_type,
        response_category: cannedMessage.response_category,
      }))
    },
  },
  watch: {
    searchKey() {
      this.fetchCannedResponses();
    },
  },
  mounted() {
    this.fetchCannedResponses();
  },
  methods: {
    isWhatsapp() {
      return (((this.selectedInbox?.inbox?.channel_type === 'Channel::Whatsapp')  || (this.currentChat?.meta?.channel === 'Channel::Whatsapp' && !this.newConversation)))
    },
    lastMessageInChat() {
      return this.lastMessage(this.currentChat);
    },
    fetchCannedResponses() {
      this.$store.dispatch('getCannedResponse', { searchKey: this.searchKey });
    },
    handleMentionClick(item = {}) {
      this.$emit('click', item);
    },
  },
};
</script>
